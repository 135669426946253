<template>
  <header-page :title="'My Profile'" :hasBackLink="true" />
  <div class="profile-form">
    <div class="form-column w-900px">
      <div>
        <div class="title title1">
          Profile info
          <template v-if="!true">
            <div
              v-if="!isActivatedToEdit"
              class="icon edit"
              v-html="Edit"
              @click="setIsActivatedToEditInputs(false)"
            ></div>
          </template>

          <div
            v-if="isActivatedToEdit"
            class="main-button sm center orange w-75px ml-25 mb-5 mt-5"
            :class="{ disabled: !isValid || !smthChanged() }"
            @click="saveProfile"
          >
            Save
          </div>
          <div
            v-if="isActivatedToEdit"
            class="main-button sm center w-75px ml-25 mb-5 mt-5"
            @click="initDefaultFormValues"
          >
            Cancel
          </div>
        </div>
        <div class="form-row">
          <div class="form-cell w-450" data-key="first_name">
            <div class="label">First name *</div>
            <input-text v-model="first_name" />
          </div>
          <div class="form-cell w-450" data-key="last_name">
            <div class="label">Last name *</div>
            <input-text v-model="last_name" />
          </div>
        </div>

        <div class="form-row">
          <div class="form-cell w-450" data-key="email">
            <div class="label">Email *</div>
            <input-text v-model="email" />
          </div>
          <div class="form-cell w-450" data-key="reported_to">
            <template v-if="!isPartnerContact && !isClientContact">
              <div class="label">Reported to</div>
              <input-text v-model="reported_to" />
            </template>
          </div>
        </div>

        <div class="form-row">
          <div class="form-cell w-450">
            <router-link class="hand link pass-btn" to="/change-password"
              >Change password</router-link
            >
          </div>
        </div>

        <div class="form-row" v-if="!isPartnerContact && !isClientContact">
          <div class="form-cell w-100">
            <grid
              v-model:columns="columns"
              v-model="my_division"
              :options="options"
              :listTabs="listTabs"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPage from '@/components/HeaderPage.vue'
import { Edit, Plus } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
import { fetchData, urls } from '@/utils/urls.js'
import Grid from '@/components/Grid.vue'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'MyProfile',
  components: { HeaderPage, InputText, Grid },

  data() {
    return {
      Edit,
      Plus,
      isActivatedToEdit: false,
      set_password_link: '',

      first_name: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
      },

      last_name: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
      },

      email: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        regexp:
          /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        readonly: false,
      },

      reported_to: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: false,
        readonly: false,
      },

      my_division: [],

      options: new GrigOptions({
        isSelectable: false,
        isMultiselect: false,
        isSortable: false,
        isMultisort: false,
        isHideFooter: true,
      }),

      columns: [
        new GridColumn({
          key: 'email',
          text: 'Email Address',
          width: 0,
          cssClass: 'bold-underline',
        }),

        new GridColumn({
          key: 'first_name',
          text: 'First name',
          width: 0,
        }),

        new GridColumn({
          key: 'last_name',
          text: 'Last name',
          width: 0,
        }),

        new GridColumn({
          key: 'role',
          text: 'Role',
          width: 0,
        }),
      ],

      listTabs: [
        {
          id: 'my_division',
          name: 'My Division',
          count: 0,
          isActive: true,
          isVisible: true,
        },
      ],
    }
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem &&
          !dataItem.isValid
        )
          return false
      }

      return true
    },

    hasPermissionToEdit() {
      if (this.isActivatedToEdit) return true
      return false
    },

    isPartnerContact() {
      return this.$store.state.currentUser?.partner ? true : false
    },

    isClientContact() {
      return this.$store.state.currentUser?.client ? true : false
    },
  },

  async created() {
    this.isActivatedToEdit = false
    this.setIsActivatedToEditInputs(!this.isActivatedToEdit)

    let values = await this.loadData()
    this.initFormValues(values)
  },

  methods: {
    setIsActivatedToEditInputs(value) {
      this.isActivatedToEdit = !value
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (typeof dataItem === 'object' && 'valueOriginal' in dataItem) {
          dataItem.readonly = !this.hasPermissionToEdit
        }
      }
    },

    smthChanged() {
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (
          dataItem &&
          typeof dataItem === 'object' &&
          'valueOriginal' in dataItem
        ) {
          if (dataItem.value && typeof dataItem.value === 'object') {
            if (dataItem.value?.length !== dataItem.valueOriginal?.length)
              return true
          } else {
            if (dataItem.value !== dataItem.valueOriginal) {
              return true
            }
          }
        }
      }

      return false
    },

    initDefaultFormValues() {
      this.setIsActivatedToEditInputs(true)
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (typeof dataItem === 'object' && 'valueOriginal' in dataItem) {
          if (dataItem?.type === 'select') {
            dataItem.value = [...dataItem.valueOriginal]
          } else {
            dataItem.value = dataItem.valueOriginal
          }
        }
      }
    },

    initFormValues(values) {
      this.my_division = values.my_division
      this.set_password_link = values.set_password_link || ''
      let entity = values.user_data

      for (const key in entity) {
        if (key in this.$data) {
          let dataItem = this.$data[key]
          if ('valueOriginal' in dataItem) {
            if (dataItem.type === 'select') {
              dataItem.value =
                typeof entity[key] === 'object' ? entity[key] : [entity[key]]
              dataItem.valueOriginal =
                typeof entity[key] === 'object' ? entity[key] : [entity[key]]
            } else {
              dataItem.value = entity[key]
              dataItem.valueOriginal = entity[key]
            }
          }
        }
      }
    },

    async loadData() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.me, 'GET')
      this.$store.commit('setIsLoading', false)
      if (!result?.detail) {
        return result
      } else console.error(result.detail)
      return null
    },

    async saveProfile() {
      if (!this.isValid) {
        console.error('isValid', false)
        return
      }

      if (!this.smthChanged()) {
        console.warn('Nothing to save')
        return
      }

      await this.saveProfileData()
    },

    async saveProfileData() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.updateMe,
        'POST',
        this.bodyToSave()
        // this.queryToSave()
      )
      if (result?.detail) {
        console.error(result.detail)
      } else {
        let values = await this.loadData()
        this.initFormValues(values)
      }

      this.$store.commit('setIsLoading', false)
    },

    bodyToSave() {
      let body = {}
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (typeof dataItem === 'object' && 'valueOriginal' in dataItem) {
          if (dataItem.value && typeof dataItem.value === 'object') {
            if (dataItem.value?.length !== dataItem.valueOriginal?.length) {
              dataItem.value.forEach((element) => {
                body = { ...body, ...{ [key]: element } }
              })
            }
          } else {
            if (dataItem.value !== dataItem.valueOriginal) {
              body = { ...body, ...{ [key]: dataItem.value } }
            }
          }
        }
      }

      return body
    },
  },
}
</script>

<style>
.pass-btn {
  padding: 10px 0;
}

.link {
  color: #fe7248;
  display: block;
}
</style>
